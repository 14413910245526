<template>
	<div>
		<div class="analysis">
			<div class="echarts">
				<h3 class="tit">车辆进出场情况(次)</h3>
				<histogram v-if="inOut.xValue.length>0" id="inOut" type="bar" Xname="闸口名称" :data="inOut"></histogram>
			</div>
			<div class="echarts">
				<h3 class="tit">数值统计</h3>
				<div class="number flex countAmount">
					<div>
						<p class="inPark">{{ count.inPark }}</p>
						<span>共计进场频次</span>
					</div>
					<div>
						<p class="outPark">{{ count.outPark }}</p>
						<span>共计出场频次</span>
					</div>
					<div>
						<p class="parkTime">{{ count.incomeAmount }}</p>
						<span>收入金额(元)</span>
					</div>
					<div>
						<p class="loss">{{ count.lossAmount }}</p>
						<span>总亏损金额(元)</span>
					</div>
					<div>
						<p class="lossCoupon">{{ count.lossCoupon }}</p>
						<span>优惠券亏损金额(元)</span>
					</div>
				</div>
			</div>
<!--            v-if="showParkingRate"-->
			<div class="echarts" >
				<h3 class="tit">停车率</h3>
				<histogram id="parkRate" type="line"  :data="parkRateOneDay"></histogram>
			</div>
		</div>
		<div class="rightCount">
			<div class="count">
				<h3 class="tit">会员类型</h3>
				<h2 class="titPark">进场:</h2>
				<echartscom id="inPark" v-if="entryCarOneDay.chartData.length > 0" :nameData="entryCarOneDay.rows"
										:chartData="entryCarOneDay.chartData"></echartscom>
				<h2 class="titPark">出场:</h2>
				<echartscom id="outParkM" v-if="sentryCarOneDay.chartData.length > 0" :nameData="sentryCarOneDay.rows"
										:chartData="sentryCarOneDay.chartData"></echartscom>
			</div>
	<!--		<div class="count">
				<h3 class="tit">停车率</h3>
				<el-progress type="circle" class="progressbar" :percentage="count.parkingRate" :stroke-width="15"
										 color="#19D4AE"></el-progress>
			</div>-->
		</div>

	</div>
</template>

<script>
export default {
	name: "areaDay",
	props: {
		inOut: {
			type: Object
		},
		entryCarOneDay: {
			type: Object
		},
		sentryCarOneDay: {
			type: Object
		},
		count: {
			type: Object
		},
		parkRateOneDay: {
			type: Object
		},
		showParkingRate:{
			type: Boolean
		}
	},
	data() {
		return {
			bar: "bar"
		};
	},
	mounted() {
	},
	watch:{
		showParkingRate: {
			handler(newval) {
				console.log(newval)
			}
		},
	},
	methods: {},
	components: {
		"echartscom": () => import("../account/echartscom"),
		"histogram": () => import("./histogram")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/statistics";

.analysis {
	width: 65%;
}

.rightCount {
	width: calc(100% - 62% - 64px);
}
</style>
